import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import scrollTo from 'gatsby-plugin-smoothscroll';
export default function Secseven(){
    return (
        <>

           <Container fluid className="__bg-primary-sl-mirror">
                <Container className="fade-in">
                    <Row className="justify-content-center">
                        <h2 className="__text-headline text-white text-center  __margin-bottom-5-wl">
                            Lihat, dengar dan rasakan testimoni jujur dari alumni online training sebelumnya..
                        </h2>
                    </Row>
                    <Row>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Jika kamu wanita dan merasakan bahwa menunggu terlalu lama untuk mendapatkan komitmen, terdapat denial atas hubungan saat ini, merasa takut gak laku kalo mutusin pacar sekarang, sebaiknya kamu hilangkan segala bentuk denial tersebut. Ikuti kelas ini”</p>
                                    <p className="__text-testimoni"><b>- Lita</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Manfaat yang saya dapatkan dari mengikuti training ini sudah mengajarkan saya untuk mengerti bagaimana komitmen itu harus dimiliki oleh kedua belah pihak baik saya sendiri dan pria yang akan dan sudah saya miliki, agar dalam hubungan pernikahan kita tidak merasa kaget dengan pasangan kita.”</p>
                                    <p className="__text-testimoni"><b> - Magdalenaocy</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Jadi lebih paham apa itu commitment,,, karena yang dibutuhkan adalah commitment bukan hanya status. “</p>
                                    <p className="__text-testimoni"><b>  - Tenri</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“ Saya jadi paham bagaimana memperlakukan laki-laki yang bisa mentriggernya untuk komitmen dengan saya. Saya juga menjadi lebih mencintai diri saya sendiri, mengarahkan saya untuk bisa memperbaiki diri dan fokus pada apa yang saya miliki.”</p>
                                    <p className="__text-testimoni"><b>- Laila</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">Saya jadi mengerti mengapa saya gagal lanjut hingga komitmen di relasi sebelumnya. <br/>

Saya belajar banyak banget dari training ini. Saya juga terapkan ilmu-ilmu baru yang saya dapat dari training tersebut dan saya merasakan perubahan dari dalam diri saya untuk merasa HAPPY & ENOUGH with myself walaupun saat ini sedang single. <br/>

Saya merasa MUCH UPGRADED dan tahu bagaimana nantinya bila sudah memiliki calon pasangan potensial lagi supaya bisa men-TRIGGER dia untuk mau dan rela berkomitmen dengan saya. :)”</p>
                                    <p className="__text-testimoni"><b>- Rahma</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Gak perlu ragu dan sebagainya karena ini sebuah investasi yang sangat berharga buat diri sendiri. Jangan pelit sama diri sendiri, ayo mulai sayangi diri kamu. Ini tidak hanya applicable untuk romansa, tapi untuk networking dan sebagainya. Dapet banget pelajaran hidupnya. Totally worth it! :) “</p>
                                    <p className="__text-testimoni"><b>- Lidya</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Coba dulu ikutan, lalu coba praktekkan.. Walaupun nantinya belum bisa dipakai untuk saat ini, bisa jadi bekal untuk di kesempatan nanti.. Dan ini bukan cuma bekal untuk proses sebelum menikah, bahkan bisa dipakai ketika sudah menikah.”</p>
                                    <p className="__text-testimoni"><b>- Indah</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        {/* <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“HARUS IKUT! Membuka wawasan & kesadaran banget untuk modal kita membangun hubungan romansa, jadi ga sabar buat praktek.”  </p>
                                    <p className="__text-testimoni"><b>-  Indah Riyanti</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Hanya buang2 waktu dan perasaan jika kalian masih ragu untuk mengikuti Attraction Mastery ini. Karna apa yang disampaikan semua 99% kita pasti mengalaminya dan kita akan dapat trik2 untuk bisa menyikapinya” </p>
                                    <p className="__text-testimoni"><b>- Fitri</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">Dijamin dengan ikut training attraction mastery ini, gak akan kehabisan ide buat menarik perhatian si gebetan untuk terus balik lagi dan jadi diriku semenarik mungkin di hati mereka, tentu dengan menjadi diriku yg se-unpredictable mungkin :)” </p>
                                    <p className="__text-testimoni"><b>- Imas Rahadita</b></p>
                                </Card.Body>
                            </Card>
                        </Col> */}
                    </Row>
                    <Row className="justify-content-center mt-5">
                        <h2 className="__text-headline text-white text-center  __margin-bottom-5-wl">
                        Dan masih banyak lagi ratusan testimoni jujur lainnya yang tidak akan cukup ditampilkan disini.<br/>Tunggu apalagi?
                        </h2>
                        <Col lg={12}>
                                    <div className="d-flex justify-content-center wl-am-fr mt-5">
                                        
                                            <Button variant="primary" size="lg" onClick={() => scrollTo('#buy-bottom')}>
                                                YA SAYA MAU DAFTAR
                                            </Button>{' '}
                                        
                                    
                                    </div>
                                   
                                        <p className="text-center pt-3 text-white __text-link" onClick={() => scrollTo('#buy-bottom')}><u>( klik disini untuk instan akses )</u></p>
                                   
                                    </Col>
                    </Row>
                    
                </Container>
            </Container>

        </>
    )
}